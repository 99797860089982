import type { CartItemType } from '~/api/cart';
import CartAPI from '~/api/cart';
import type { ProductType } from '~/api/common';
import type { ProductVariantType } from '~/api/product';

const MODAL_KEY = 'product-cart';
const CART_DATA_VERSION = 1;
const CART_DATA_STORAGE_KEY = 'cart-data';

export const useProductCartStore = defineStore('cart', () => {
  const t = useNuxtApp().$i18n.t;
  const userStore = useUserStore();
  const modalStore = useModalStore();
  const dataLayer = useDataLayer();
  const commonStore = useCommonStore();
  const subscribeProductsStore = useSubscribeProductsStore();

  const opened = computed({
    get: () => !!modalStore.modalKeys[MODAL_KEY],
    set: (v) => modalStore.toggleModal(MODAL_KEY, v),
  });
  const open = () => modalStore.openModal(MODAL_KEY);
  const close = () => modalStore.closeModal(MODAL_KEY);

  const cartData = ref<CartItemType[]>([]);
  const timers = ref<Record<number, NodeJS.Timeout>>({});

  const offlineStore = useOfflineStore(
    cartData,
    CART_DATA_STORAGE_KEY,
    CART_DATA_VERSION,
  );

  const totalCount = computed(() =>
    cartData.value.reduce(
      (acc, item) => acc + (!item.isGift ? item.quantity : 0),
      0,
    ),
  );
  const totalCountAvailable = computed(() =>
    cartData.value.reduce(
      (acc, item) =>
        acc + (item.isAvailable && !item.isGift ? item.quantity : 0),
      0,
    ),
  );

  const totalPriceAvailable = computed(() =>
    cartData.value.reduce(
      (acc, item) =>
        acc +
        (item.isAvailable && !item.isGift ? item.price * item.quantity : 0),
      0,
    ),
  );

  const clearCartData = () => {
    cartData.value = [];
    offlineStore.reset();
  };

  const initCartData = () => {
    if (userStore.authorized) {
      CartAPI.contents().then((data) => {
        cartData.value = data.items;
      });
    }

    offlineStore.init();
  };

  const checkDelta = (
    data: CartItemType[],
    skuId: number,
    quantity: number,
  ) => {
    const product = data.find((el) => el.skuId === skuId);
    if (
      (!product && quantity > 0) ||
      (product && quantity > product.quantity)
    ) {
      modalStore.showInfo({
        title: t('store.cart.message.count_error'),
        action: t('store.cart.message.action'),
      });
    }
  };

  const saveItem = (skuId: number, quantity: number) => {
    CartAPI.updateItem({ body: { id: skuId, quantity } })
      .then((data) => {
        cartData.value = data.items;

        checkDelta(data.items, skuId, quantity);
      })
      .catch(() => {
        modalStore.showError({
          title: t('store.cart.message.update_error'),
          action: t('store.cart.message.action'),
        });
      });
  };

  const deleteItem = (skuId: number) => {
    CartAPI.removeItem({ body: { id: skuId } })
      .then((data) => {
        cartData.value = data.items;
      })
      .catch(() => {
        modalStore.showError({
          title: t('store.cart.message.update_error'),
          action: t('store.cart.message.action'),
        });
      });
  };

  const synchronize = (skuId: number, quantity: number) => {
    CartAPI.synchronize({
      body: cartData.value.map((el) => ({
        id: el.skuId,
        quantity: el.quantity,
      })),
    }).then((data) => {
      cartData.value = data.items;

      if (quantity > 0) checkDelta(data.items, skuId, quantity);
    });
  };

  const updateCartItem = (item: CartItemType, append = false) => {
    let quantity = item.quantity;
    const cartItem = cartData.value.find((el) => el.skuId === item.skuId);
    const parentItem = commonStore.products.find(
      (product) => product.id === item.productId,
    );
    const quantityDelta = append
      ? item.quantity
      : item.quantity - (cartItem?.quantity || 0);
    if (!quantityDelta) return;

    dataLayer.append(
      {
        event: quantityDelta > 0 ? 'addToCart' : 'removeFromCart',
        ecommerce: {
          currencyCode: 'RUB',
          [quantityDelta > 0 ? 'add' : 'remove']: {
            products: [
              {
                id: item.skuId,
                name: item.name,
                price: item.price,
                quantity: Math.abs(quantityDelta),
                xml_id: parentItem?.xmlId,
              },
            ],
          },
        },
      },
      ['user'],
    );

    if (cartItem) {
      quantity = (append ? cartItem.quantity : 0) + item.quantity;
      cartItem.quantity = quantity;
      if (quantity < 1) {
        cartData.value.splice(cartData.value.indexOf(cartItem), 1);

        if (
          parentItem?.refillId &&
          subscribeProductsStore.productIds.some(
            (id) => id === parentItem.refillId,
          )
        )
          subscribeProductsStore.onDelete(parentItem.refillId);
      }
    } else if (item.quantity > 0) {
      cartData.value.push(item);
    }

    if (timers.value[item.skuId]) clearTimeout(timers.value[item.skuId]);
    timers.value[item.skuId] = setTimeout(() => {
      if (userStore.authorized) {
        if (quantity > 0) saveItem(item.skuId, quantity);
        else deleteItem(item.skuId);
      } else synchronize(item.skuId, quantity);

      delete timers.value[item.skuId];
    }, 500);
  };

  const updateCartItemByProductInfo = (
    productInfo: ProductType,
    quantity: number,
    append = false,
  ) => {
    if (productInfo.addToBasketId && productInfo.isAvailable) {
      updateCartItem(
        {
          quantity,
          skuId: productInfo.addToBasketId,
          productId: productInfo.id,
          name: productInfo.name,
          code: productInfo.code,
          price: productInfo.priceInfo.price,
          basePrice: productInfo.priceInfo.basePrice,
          isAvailable: productInfo.isAvailable,
        },
        append,
      );
    }
  };

  const updateCartItemBySkuInfo = (
    skuInfo: ProductVariantType,
    productId: number,
    productName: string,
    quantity: number,
    append = false,
  ) => {
    if (skuInfo.isAvailable) {
      updateCartItem(
        {
          quantity,
          skuId: skuInfo.id,
          productId,
          name: `${productName} (${skuInfo.distinct})`,
          code: skuInfo.skuCode,
          price: skuInfo.price,
          basePrice: skuInfo.basePrice,
          isAvailable: skuInfo.isAvailable,
          isGift: skuInfo.isGift,
        },
        append,
      );
    }
  };

  const transferCartData = async () => {
    if (!userStore.authorized || !cartData.value.length) return;

    return CartAPI.transfer({
      body: cartData.value.map((el) => ({
        id: el.skuId,
        quantity: el.quantity,
      })),
    })
      .then((data) => {
        if (
          data.items.reduce((acc, el) => acc + el.quantity, 0) >
          cartData.value.reduce((acc, el) => acc + el.quantity, 0)
        ) {
          modalStore.showInfo({
            title: t('store.cart.message.transfer_info'),
            action: t('store.cart.message.continue'),
          });
        }

        cartData.value = data.items;
        offlineStore.reset();
      })
      .catch(() => {
        modalStore.showError({
          title: t('store.cart.message.transfer_error'),
          action: t('store.cart.message.action'),
        });
      });
  };

  return {
    opened,
    open,
    close,

    initialized: offlineStore.initialized,
    cartData,
    totalCount,
    totalCountAvailable,
    totalPriceAvailable,
    clearCartData,
    initCartData,
    updateCartItem,
    updateCartItemByProductInfo,
    updateCartItemBySkuInfo,
    transferCartData,
  };
});
